import React, { useEffect } from 'react';

function Index() {
    useEffect(() => {
        // Redirect to coinbase.com
        window.location.href = 'https://www.coinbase.com';
    }, []);

    return (
        <div></div>
    );
}

export default Index;
